/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, Suspense, lazy as lazyLoadedComponent } from "react";
import {
  Switch,
  Route as ReactRoute,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import UploadResumeBeforeApplyingToJobPage from "@/pages/UploadResumeBeforeApplyingToJobPage/UploadResumeBeforeApplyingToJobPage";
import {
  showActivateIntroModal,
  showActivatePitchModal
} from "@/redux/actions/globalStateActions";
import { trackLinkClickApi } from "@/services/utilitiesService";
import LoaderLg from "@/components/LoaderLg";
import "react-phone-input-2/lib/style.css";
import Navbar from "@/dashboards/Navbar";
import FaqPageByMode from "@/pages/FaqPageByMode/FaqPageByMode";
import RemoveTrailingSlash from "@/utils/RemoveTrailingSlash";
import ErrorPage from "@/pages/ErrorPage";
import AiInterviewBuilder from "@/pages/AiInterviewBuilder";
import AiInterviewDashboard from "@/dashboards/AIInterviewDashboard/AiInterviewDashboard";
import AiInterviewViewAssessment from "@/pages/AiInterviewViewAssessment";
import ViewCandidateInterviewReport from "@/pages/ViewCandidateInterviewReport/ViewCandidateInterviewReport";
import AiInterviewLandingPage from "@/pages/AiInterviewLandingPage";

const HirerContactPage = lazyLoadedComponent(() =>
  import("@/pages/HirerContactForm/HirerContactForm")
);
const InvestorsPitchPage = lazyLoadedComponent(() =>
  import("@/pages/InvestorsPitch")
);
const JobSeekerOnboardingV2 = lazyLoadedComponent(() =>
  import("@/pages/OnboardingPage/JobSeekerV2/JobSeekerOnboardingV2")
);
const ccAvenue = lazyLoadedComponent(() =>
  import("@/components/CcAvenue")
);
const AutoRecruiterDetailsPage = lazyLoadedComponent(() =>
  import("@/pages/AutoRecruiterDetailsPage")
);
const JobSeekerListsByType = lazyLoadedComponent(() =>
  import("@/pages/JobSeekerListsByType")
);
const ProfilePage = lazyLoadedComponent(() => import("@/pages/ProfilePageV3"));
const HirePage = lazyLoadedComponent(() => import("@/pages/HirePage"));
const JobsPage = lazyLoadedComponent(() => import("@/pages/JobsPageV3"));
const AutoRecruiterLandingPage = lazyLoadedComponent(() =>
  import("@/pages/AutoRecruiterLandingPage")
);
const AtsLandingPage = lazyLoadedComponent(() =>
  import("@/pages/ATS/AtsLandingPage")
);
const AtsAnalyserPage = lazyLoadedComponent(() =>
  import("@/pages/ATS/AtsAnalyserPage")
);
const PremiumCart = lazyLoadedComponent(() =>
  import("@/pages/Cart/PremiumCart")
);
const InterviewPage = lazyLoadedComponent(() =>
  import("@/pages/InterviewPage")
);
const InterviewPageV2 = lazyLoadedComponent(() =>
  import("@/pages/InterviewPageV2/InterviewPageV2")
);
const MemberPremiumPage = lazyLoadedComponent(() =>
  import("@/pages/MemberPremiumPage")
);
const DeleteAccountPage = lazyLoadedComponent(() =>
  import("@/pages/DeleteAccountPage")
);
const UnsubscribePage = lazyLoadedComponent(() =>
  import("@/pages/UnsubscribePage")
);
const FeedbackUserPage = lazyLoadedComponent(() =>
  import("@/pages/FeedbackUserPage")
);
const ChatServicePage = lazyLoadedComponent(() =>
  import("@/pages/ChatServicePage")
);
const MyPostedJobsPage = lazyLoadedComponent(() =>
  import("@/pages/MyPostedJobsPage")
);
const JobCandidatesPage = lazyLoadedComponent(() =>
  import("@/pages/JobCandidatesPage")
);
const PostRequirementPage = lazyLoadedComponent(() =>
  import("@/pages/PostRequirementPage")
);
const NotificationsPage = lazyLoadedComponent(() =>
  import("@/pages/NotificationsPage")
);
const GCoinsPage = lazyLoadedComponent(() => import("@/pages/GCoinsPage"));
const MyJobOffers = lazyLoadedComponent(() => import("@/pages/MyJobOffers"));
const HomePage = lazyLoadedComponent(() => import("@/pages/LandingPageV3"));
const SurveyPage = lazyLoadedComponent(() => import("@/pages/SurveyPage"));
const InTheMediaPage = lazyLoadedComponent(() =>
  import("@/pages/InTheMediaPage")
);
const OnboardingLayout = lazyLoadedComponent(() =>
  import("@/layout/OnboardingLayout")
);
const SocialOnboardingPage = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/OnboardingPage")
);
const CandidatePreference = lazyLoadedComponent(() =>
  import(
    "@/pages/OnboardingPageV2/Hirer/CandidatePreference/CandidatePreference"
  )
);
const HirerDashboard = lazyLoadedComponent(() =>
  import("@/dashboards/HirerDashboardV2")
);
const CreateJobPage = lazyLoadedComponent(() =>
  import("@/pages/Hirer/CreateJobPage/CreateJobPage")
);
const UpdateJobPage = lazyLoadedComponent(() =>
  import("@/pages/Hirer/UpdateJobPage/UpdateJobPage")
);
const SelectHirersForPitch = lazyLoadedComponent(() =>
  import("@/pages/SelectHirersForPitch/SelectHirersForPitch")
);
const RequestSurveyPage = lazyLoadedComponent(() =>
  import("@/pages/RequestSurveyPage")
);
const SearchCandidatesPage = lazyLoadedComponent(() =>
  import("@/pages/SearchCandidatesPage/SearchCandidatesPage")
);
const EditHirerProfilePage = lazyLoadedComponent(() =>
  import("@/pages/EditHirerProfilePage/EditHirerProfilePage")
);
const CompleteTransactionForAutoRecruiterOwnData = lazyLoadedComponent(() =>
  import(
    "@/pages/CompleteTransactionForAutoRecruiterOwnData/CompleteTransactionForAutoRecruiterOwnData"
  )
);
const HirerPremiumPage = lazyLoadedComponent(() =>
  import("@/pages/HirerPremiumPage")
);
const ManageParticularJobPage = lazyLoadedComponent(() =>
  import("@/pages/ManageParticularJobV2")
);
const BoostParticularJobPage = lazyLoadedComponent(() =>
  import("@/pages/BoostParticularJobV2")
);
const OnboardingChatUi = lazyLoadedComponent(() =>
  import("@/pages/OnboardingChatBasedPage")
);
const JobPreviewPage = lazyLoadedComponent(() =>
  import("@/pages/JobPreviewPage")
);
const PaymentStatus = lazyLoadedComponent(() =>
  import("@/pages/PaymentStatus")
);
const MemberDashboard = lazyLoadedComponent(() =>
  import("@/dashboards/MemberDashboardV2")
);
const JobSeekerPremiumPage = lazyLoadedComponent(() =>
  import("@/pages/JobSeekerPremiumPage")
);
const RecommendedCandidates = lazyLoadedComponent(() =>
  import("@/pages/RecommendedCandidates")
);
const InvestorsLandingPage = lazyLoadedComponent(() =>
  import("@/pages/InvestorsLandingPage")
);
const GenerateCvPage = lazyLoadedComponent(() =>
  import(
    "@/pages/OnboardingPage/JobSeeker/ResumeAdded/GenerateCV/GenerateCvPage"
  )
);

const AICareerCounsellor = lazyLoadedComponent(() => import("@/pages/PremiumProducts/AICareerCounsellor"));
const UltimateCareerKitPage = lazyLoadedComponent(() => import("@/pages/PremiumProducts/UltimateCareerKitPage"));
const FitnessCounsellor = lazyLoadedComponent(() => import("@/pages/PremiumProducts/FitnessCounsellor"));
const LifeCoach = lazyLoadedComponent(() => import("@/pages/PremiumProducts/LifeCoach"));
const AITrainer = lazyLoadedComponent(() => import("@/pages/PremiumProducts/AITrainer"));
const InterviewCoaching = lazyLoadedComponent(() => import("@/pages/PremiumProducts/InterviewCoaching"));
const JumpTheQueue = lazyLoadedComponent(() => import("@/pages/PremiumProducts/JumpTheQueue"));
const InterviewSuccess = lazyLoadedComponent(() => import("@/pages/PremiumProducts/InterviewSuccess"));
const CareerGrowthBundle = lazyLoadedComponent(() => import("@/pages/PremiumProducts/CareerGrowthBundle"));
const CareerStarterBundle = lazyLoadedComponent(() => import("@/pages/PremiumProducts/CareerStarterBundle"));
const UnlockRecruiterContactDetails = lazyLoadedComponent(() => import("@/pages/PremiumProducts/UnlockRecruiterContactDetails"));
const SkillGapAnalysis = lazyLoadedComponent(() => import("@/pages/PremiumProducts/SkillGapAnalysis"));
const AIResumeBuilder = lazyLoadedComponent(() => import("@/pages/PremiumProducts/AIResumeBuilder"));
const FTFCareerGuidance = lazyLoadedComponent(() => import("@/pages/PremiumProducts/FTFCareerGuidance"));
const GoodnessScorePage = lazyLoadedComponent(() =>
  import("@/pages/GoodnessScorePage/GoodnessScorePage")
);

const ManageYourProducts = lazyLoadedComponent(() =>
  import("@/pages/ManageYourProducts/ManageYourProducts")

);

const CompanyDetails = lazyLoadedComponent(() =>
  import("@/pages/OnboardingPageV2/Hirer/CompanyDetails/CompanyDetails")
);
const SavedJobsPage = lazyLoadedComponent(() =>
  import("@/pages/SavedJobsPage")
);
const OnboardingPage = lazyLoadedComponent(() =>
  import("@/pages/OnboardingPage")
);
const ApplicationsPage = lazyLoadedComponent(() =>
  import("@/pages/ApplicationsPage/ApplicationsPage")
);
const ParticularJobPage = lazyLoadedComponent(() =>
  import("@/pages/JobsPageV3/ParticularJobPage")
);

const DetailedFeedback = lazyLoadedComponent(() =>
  import("@/components/DetailedFeedback/DetailedFeedback")
);
const RecruiterView = lazyLoadedComponent(() =>
  import("@/components/RecruiterView/RecruiterView")
);
// const MoreJobs = lazyLoadedComponent(() => import("@"));
const SocialApplicationPage = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/ApplicationPage/ApplicationPage")
);
const PurchaseGCoinsPage = lazyLoadedComponent(() =>
  import("@/pages/PurchaseGCoinsPage")
);
const HirerActivityPage = lazyLoadedComponent(() =>
  import("@/pages/HirerActivityPage")
);
const MyIntroPage = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/MyIntroPage/MyIntroPage")
);
const PaymentSuccessful = lazyLoadedComponent(() =>
  import("@/pages/ActivateAutoRecruiterOnJobPage/PaymentSuccessful")
);
const CreatePitchPage = lazyLoadedComponent(() =>
  import("@/pages/CreatePitchPage")
);
const CareerCounselor = lazyLoadedComponent(() =>
  import("@/pages/CareerCounselor")
);
// const RohanBot = lazyLoadedComponent(() =>
//   import("@/pages/Rohan")
// );
const Fitness = lazyLoadedComponent(() =>
  import("@/pages/Fitness")
);
const AiLifeCoach = lazyLoadedComponent(() =>
  import("@/pages/LifeCoach")
);

const MockInterviewer = lazyLoadedComponent(() =>
  import("@/pages/MockInterview")
);
const SkillGapAnalyser = lazyLoadedComponent(() =>
  import("@/pages/SkillGapAnalysis")
);
const AiTrainer = lazyLoadedComponent(() =>
  import("@/pages/AiTrainer")
);
const CreateIntroPage = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/CreateIntroPage/CreateIntroPage")
);
const PreviewIntroPage = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/CreateIntroPage/PreviewIntroPage")
);
const SelectUsersForIntro = lazyLoadedComponent(() =>
  import("@/dashboards/Social/pages/SelectUsersForIntro/SelectUsersForIntro")
);
const PitchPreviewPage = lazyLoadedComponent(() =>
  import("@/pages/CreatePitchPage/PitchPreviewPage")
);
const HirerGcoinsWalletPage = lazyLoadedComponent(() =>
  import("@/dashboards/HirerDashboardV2/GcoinsWallet/HirerGcoinsWalletPage")
);
const AutoInterviewDemoPage = lazyLoadedComponent(() =>
  import("@/pages/AutoInterviewDemoPage")
);
const AutoInterviewDemoPageCvent = lazyLoadedComponent(() =>
  import("@/pages/AutoInterviewDemoPageCvent")
);
const RedirectPage = lazyLoadedComponent(() => import("@/pages/RedirectPage"));
const RecruitOwnData = lazyLoadedComponent(() =>
  import("@/pages/RecruitOwnData/RecruitOwnData")
);
const ProtectedRoute = lazyLoadedComponent(() => import("./ProtectedRoute"));
const ScrollToTopAdapter = lazyLoadedComponent(() =>
  import("./ScrollToTopAdapter")
);
const SocialDashboard = lazyLoadedComponent(() =>
  import("../dashboards/Social/dashboardV2/Dashboard")
);
const SocialInvitationsPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/InvitationsPage/InvitationsPage")
);
const SocialProfilePage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/SocialProfilePage/SocialProfilePage")
);
const SocialConnectionsPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/ConnectionsPage/ConnectionsPage")
);
const SocialMyPostsPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/MyPostsPage/MyPostsPage")
);
const SocialParticularExperiencePage = lazyLoadedComponent(() =>
  import(
    "../dashboards/Social/pages/ParticularExperiencePage/ParticularExperiencePage"
  )
);
const SocialPremiumPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/PremiumPage/PremiumPage")
);
const SocialFindPeoplePage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/SocialFindPeoplePage/SocialFindPeoplePage")
);
const SocialExperiencesPage = lazyLoadedComponent(() =>
  import(
    "../dashboards/Social/pages/SocialExperiencesPage/SocialExperiencesPage"
  )
);
const SearchProfilesPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/SearchProfilesPage")
);
const ExperienceDetailsPage = lazyLoadedComponent(() =>
  import("../dashboards/Social/pages/ExperienceDetailsPage")
);
const BloodDonors = lazyLoadedComponent(() => import("../pages/BloodDonors"));
const FoundViaSearch = lazyLoadedComponent(() =>
  import("../pages/FoundViaSearch/FoundViaSearch")
);
const OnboardingChatbot = lazyLoadedComponent(() =>
  import("../pages/OnboardingChatbot")
);
const AddUserSkills = lazyLoadedComponent(() =>
  import("../pages/ProfilePageV2/UserSkills/AddSkills")
);
const ActivateAutoRecruiterOnJobPage = lazyLoadedComponent(() =>
  import("../pages/ActivateAutoRecruiterOnJobPage")
);

const FaqPage = lazyLoadedComponent(() => import("@/pages/FaqPage"));
const AboutUsPage = lazyLoadedComponent(() => import("@/pages/AboutUsPage"));
const OurTeamPage = lazyLoadedComponent(() => import("@/pages/OurTeamPage"));
const CareersPage = lazyLoadedComponent(() => import("@/pages/CareersPage"));
const PricingPage = lazyLoadedComponent(() => import("@/pages/PricingPage"));
const ContactUsPage = lazyLoadedComponent(() =>
  import("@/pages/ContactUsPage")
);

// const TermsOfServicePage = lazyLoadedComponent(() =>
//   import("@/pages/TermsOfServicePage")
// );
// const PrivacyPolicyPage = lazyLoadedComponent(() =>
//   import("@/pages/PrivacyPolicyPage")
// );
// const RefundPolicyPage = lazyLoadedComponent(() =>
//   import("@/pages/RefundPolicyPage")
// );

const BlogsPage = lazyLoadedComponent(() => import("@/pages/BlogsPage"));
const BlogDetails = lazyLoadedComponent(() =>
  import("@/pages/BlogsPage/DetailView")
);
const TermsAndPolicy = lazyLoadedComponent(() =>
  import("@/pages/TermsAndPolicy")
);
const SocialPage = lazyLoadedComponent(() =>
  import("@/dashboards/SocialPageV2")
);

const homepage = false && isMobile ? "/job-seeker" : "/hirer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Route = ReactRoute;
export default function RouterConfig() {
  const loggedInUser = useSelector((state) => state.user);
  const onboardingStatus = useSelector(
    (state) => state.globalState.onboardingStatus
  );
  const query = useQuery();
  const inviteUserId = query.get("invite");
  const history = useHistory();
  const location = useLocation();
  let userKeyword;
  if (loggedInUser.user_id) {
    userKeyword = `${loggedInUser.name
      ? loggedInUser.name.replaceAll(" ", "-").toLowerCase()
      : ""
      }-${loggedInUser.user_id}`;
  }

  function extractTypeFromUrl(url) {
    const urlObj = new URL(url);
    const splitPathname = urlObj.pathname.split('/');
    const filteredPathname = splitPathname.filter(Boolean);
    return filteredPathname[0];
  }

  useEffect(() => {
    const source = query.get("source");
    const utm_source = query.get("utm_source");
    if (utm_source) {
      const utm_medium = query.get("utm_medium");
      const utm_campaign = query.get("utm_campaign");
      const utm_content = query.get("utm_content");
      const utmTracking = {
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        content: utm_content
      };
      localStorage.setItem("utmTrackingData", JSON.stringify(utmTracking));
      const id = query.get("id");
      console.log(location.pathname);
      if (localStorage.getItem("token")) {
        if (
          location.pathname === "/" ||
          location.pathname === "/job-seeker" ||
          location.pathname === "/hirer"
        ) {
          history.push("/dashboard");
        } else
          history.push(
            id ? `${location.pathname}?id=${id}` : location.pathname
          );
      } else if (location.pathname === "/") history.push("/hirer");
      else {
        console.priorityLog("here");
        history.push(id ? `${location.pathname}?id=${id}` : location.pathname);
      }
    }
    // profile;
    // feedback;
    // notifications;
    // gcoins;
    // chats;
    // my_job_offers;
    // hire;
    // jobs;
    if (source) {
      console.priorityLog({ source });
      if (localStorage.getItem("token")) {
        console.log("tracking click");
        // console.log(location);
        // history.push(location.pathname);

        const id = query.get("id");
        console.log(location.pathname);
        history.push(id ? `${location.pathname}?id=${id}` : location.pathname);
        trackLinkClickApi({
          linkType:
            location.pathname.replace("/", "").includes("jobs/") ||
              location.pathname.replace("/", "").includes("job/")
              ? "job"
              : location.pathname.replace("/", ""),
          source
        });
      } else {
        console.priorityLog(location.pathname);
        if (
          location.pathname.includes("jobs") ||
          (location.pathname.includes("hire") &&
            location.pathname.includes("hirer"))
        ) {
          const id = query.get("id");
          console.priorityLog("here2");
          // if (!location.pathname.includes("manage-job"))
          history.push(
            id ? `${location.pathname}?id=${id}` : location.pathname
          );
        } else {
          console.priorityLog("here");
          history.push(location.pathname);
        }
        sessionStorage.setItem(
          "sourceLink",
          JSON.stringify({
            source,
            linkType: location.pathname.replace("/", "")
          })
        );
        localStorage.setItem(
          "inviteUserId",
          `campaign_${source.replace("campaign_", "")}`
        );
      }
    }

    if (inviteUserId) {
      if (userKeyword) history.push(`/${userKeyword}`);
      else history.push(homepage);
      window.localStorage.setItem("inviteUserId", inviteUserId);
    }
    const urlType = extractTypeFromUrl(window.location.href)
    window.localStorage.setItem("urlModeType", urlType);
    console.log(window.location.href);
    if (location.pathname === "/socials/instagram") {
      const accessToken = query.get("code");
      if (accessToken)
        sessionStorage.setItem("instagramAccessToken", accessToken);
    } else if (location.pathname === "/socials/spotify") {
      const accessToken = query.get("code");
      if (accessToken)
        sessionStorage.setItem("spotifyAccessToken", accessToken);
    } else if (location.pathname === "/socials/facebook") {
      const accessToken = query.get("code");
      if (accessToken)
        sessionStorage.setItem("facebookAccessToken", accessToken);
    }
  }, []);
  useEffect(() => {
    if (location.pathname === "/") {
      history.push(homepage);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token") && sessionStorage.getItem("sourceLink")) {
      const data = JSON.parse(sessionStorage.getItem("sourceLink"));
      sessionStorage.removeItem("sourceLink");
      trackLinkClickApi(data);
    }
  }, [localStorage.getItem("token")]);
  console.log(inviteUserId);

  if (window.location.href.includes("#faqs")) {
    history.push("/faq");
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const isProfileUrl =
      location.pathname.split("-").length > 1 &&
      !Number.isNaN(parseInt(location.pathname.split("-")[1], 10));
    if (
      (loggedInUser.status === 2 || onboardingStatus) &&
      !location.pathname.includes("onboarding") &&
      !location.pathname.includes("auto-recruiter-demo") &&
      !location.pathname.includes("interview") &&
      !location.pathname.includes("investors") &&
      !location.pathname.includes("investor-pitch") &&
      !location.pathname.includes("faq-chat") &&
      !location.pathname.includes("/blood-donors") &&
      !location.pathname.includes("/transaction/track") &&
      !location.pathname.includes("/premium/job-seeker") &&
      !(loggedInUser.userMode === "SOCIAL_SERVICES" && isProfileUrl)
    ) {
      console.log("here");

      history.push("/onboarding");
    }
    if (location.pathname === "/purchase-pitch") {
      dispatch(showActivatePitchModal());
    }
    if (location.pathname === "/purchase-intro") {
      dispatch(showActivateIntroModal());
    }
    if (location.pathname.includes("/job/")) {
      console.log(location);
      history.push(location.pathname.replace("job", "jobs") + location.search);
    }
    if (location.pathname.includes("/social/user/")) {
      const keyword = location.pathname.substring("/social/user/".length);
      const keywordSplit = keyword.split("-");
      const userId = keywordSplit[keywordSplit.length - 1];
      if (parseInt(userId, 10) === parseInt(loggedInUser.user_id, 10))
        history.push("/social/profile");
      else {
        sessionStorage.setItem("startingIdInProfiles", userId);
        if (document.documentElement.clientWidth <= 1200)
          history.push("/social/find-people");
        else history.push("/social/app");
      }
    }
  }, [location.pathname]);
  console.log(location.pathname);
  console.log({ loggedInUser });
  if (location.pathname === "/") {
    history.push(homepage);
    return null;
  }



  return (
    <Suspense fallback={<LoaderLg />}>
      <ScrollToTopAdapter>
        <RemoveTrailingSlash />
        <Switch>
          <Route
            exact
            path="/"
            render={() => <AutoRecruiterLandingPage hirerMode />}
          />
          {/* <ProtectedRoute
            exact
            path="/onboarding-chat"
            component={OnboardingChatbot}
          /> */}
          <Route
            path="/onboarding-chat"
            render={() =>
              loggedInUser.user_id &&
                (onboardingStatus || loggedInUser.status === 2) ? (
                <OnboardingChatbot />
              ) : (
                <Redirect to={homepage} />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            render={() =>
              loggedInUser.user_id ? (
                loggedInUser.status === 2 ? (
                  <Redirect to="/onboarding" />
                ) : loggedInUser.userMode === "HIRING" ? (
                  <Redirect to="/hirer/dashboard" />
                ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                  <Redirect to="/member/dashboard" />
                ) : (
                  <Redirect to="/socialPage" />
                )
              ) : (
                <Redirect to="/hirer" />
              )
            }
          />
          <Route 
            exact
            path="/hirer/create-interview/:id"
            render={() => loggedInUser.user_id ? (
              loggedInUser.status === 2 ? (
                <Redirect to='/onboarding' />
              ) : loggedInUser.userMode === "HIRING" ? (
                <AiInterviewBuilder />
              ) : (
                <Redirect to="/member/dashboard" />
              )
            ) : ( <Redirect to="/hirer" /> )}
          />
          <Route 
            exact
            path="/hirer/interview-dashboard"
            render={() => loggedInUser.user_id ? (
              loggedInUser.status === 2 ? (
                <Redirect to='/onboarding' />
              ) : loggedInUser.userMode === "HIRING" ? (
                <AiInterviewDashboard />
              ) : (
                <Redirect to="/member/dashboard" />
              )
            ) : ( <Redirect to="/hirer" /> )}
          />
          <Route 
            exact
            path="/manage-interviews/:id"
            render={() => loggedInUser.user_id ? (
              loggedInUser.status === 2 ? (
                <Redirect to='/onboarding' />
              ) : loggedInUser.userMode === "HIRING" ? (
                <AiInterviewViewAssessment />
              ) : (
                <Redirect to="/member/dashboard" />
              )
            ) : ( <Redirect to="/hirer" /> )}
          />
          <Route 
            exact
            path="/hirer/candidate-report/:offerId/:userId"
            render={() => loggedInUser.user_id ? (
              loggedInUser.status === 2 ? (
                <Redirect to='/onboarding' />
              ) : loggedInUser.userMode === "HIRING" ? (
                <ViewCandidateInterviewReport />
              ) : (
                <Redirect to="/member/dashboard" />
              )
            ) : ( <Redirect to="/hirer" /> )}
          />
          <Route 
            exact
            path="/ai-interview"
            component={AiInterviewLandingPage}
          />
          <Route
            exact
            path="/job-seeker"
            render={() =>
              loggedInUser.user_id ? (
                loggedInUser.status === 2 ? (
                  <Redirect to="/onboarding" />
                ) : loggedInUser.userMode === "HIRING" ? (
                  <Redirect to="/hirer/dashboard" />
                ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                  <Redirect to="/member/dashboard" />
                ) : (
                  <Redirect to="/socialPage" />
                )
              ) : (
                <HomePage />
              )
            }
          />
          <Route
            exact
            path="/hirer"
            render={() => {
              // console.log("i ma here");
              if (sessionStorage.getItem("no-onboarding")) {
                sessionStorage.removeItem("no-onboarding");
                return <AutoRecruiterLandingPage hirerMode />;
              }
              console.log(
                loggedInUser.user_id,
                loggedInUser.status,
                loggedInUser.userMode
              );
              return loggedInUser.user_id ? (
                loggedInUser.status === 2 ? (
                  <Redirect to="/onboarding" />
                ) : loggedInUser.userMode === "HIRING" ? (
                  <Redirect to="/hirer/dashboard" />
                ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                  <Redirect to="/member/dashboard" />
                ) : (
                  <Redirect to="/socialPage" />
                )
              ) : (
                // <HomePage hirerMode />
                <AutoRecruiterLandingPage hirerMode />
              );
            }}
          />
          <Route
            exact
            path="/survey/:user_id/:user_id1"
            component={SurveyPage}
          />
          <Route exact path="/press-room" component={InTheMediaPage} />
          <Route exact path="/hire/contact-from" component={HirerContactPage} />
          <Route exact path="/hire" component={SearchCandidatesPage} />
          <Route path="/hire/:type" component={HirePage} />
          <Route path="/jobs/:jobData" component={ParticularJobPage} />
          <Route path="/jobs" component={JobsPage} />
          <Route path="/feedback/:offerId" component={DetailedFeedback} />
          <Route path="/recruiter-view" component={RecruiterView} />
          <ProtectedRoute
            exact
            path="/investor-pitch"
            component={InvestorsPitchPage}
          />
          <Route
            exact
            path="/onboarding/job-seeker/:step"
            component={JobSeekerOnboardingV2}
          />
          <ProtectedRoute
            exact
            path="/auto-recruiter"
            component={AutoRecruiterDetailsPage}
          />
          <ProtectedRoute
            exact
            path="/auto-recruiter/your-own-data"
            component={RecruitOwnData}
          />
          <ProtectedRoute
            exact
            path="/auto-recruiter/activate-on-own-data"
            component={CompleteTransactionForAutoRecruiterOwnData}
          />
          <ProtectedRoute
            path="/social/app"
            component={SocialApplicationPage}
          />
          <ProtectedRoute
            exact
            path="/interview/:offerId"
            component={InterviewPage}
          />
          <ProtectedRoute
            exact
            path="/interview-v2/:offerId"
            component={InterviewPageV2}
          />
          <Route
            exact
            path="/job-apply/:offerId"
            component={UploadResumeBeforeApplyingToJobPage}
          />
          <Route
            exact
            path="/interview/:offerId/:hash"
            component={InterviewPage}
          />
          <Route
            exact
            path="/interview-v2/:offerId/:hash"
            component={InterviewPageV2}
          />
          {/* <Route
            exact
            path="/ccAvenueTest"
            component={ccAvenue}
          /> */}

          <ProtectedRoute
            path="/social/invitations"
            component={SocialInvitationsPage}
          />
          <ProtectedRoute
            path="/social/connections"
            component={SocialConnectionsPage}
          />
          <ProtectedRoute
            exact
            path="/social/my-posts"
            component={SocialMyPostsPage}
          />
          <ProtectedRoute
            path="/social/my-posts/:socialExpId"
            component={SocialParticularExperiencePage}
          />
          <ProtectedRoute
            exact
            path="/profile/generate-cv"
            component={GenerateCvPage}
          />
          <Route
            exact
            path="/suggested-candidates"
            component={RecommendedCandidates}
          />





          <Route exact path="/premium/ats" component={AtsLandingPage} />
          <Route exact path="/premium/ats/analysis" component={AtsAnalyserPage} />
          <Route exact path="/premium/cart" component={PremiumCart} />

          <Route exact path="/premium/ai-resume-builder" component={AIResumeBuilder} />
          <Route exact path="/premium/skill-gap-analysis" component={SkillGapAnalysis} />
          <Route exact path="/premium/unlock-recruiters-contact-details" component={UnlockRecruiterContactDetails} />
          <Route exact path="/premium/jump-the-queue" component={JumpTheQueue} />
          <Route exact path="/premium/interview-success" component={InterviewSuccess} />
          <Route exact path="/premium/interview-coaching" component={InterviewCoaching} />
          <Route exact path="/premium/career-starter" component={CareerStarterBundle} />
          <Route exact path="/premium/career-growth" component={CareerGrowthBundle} />
          <Route exact path="/premium/ai-trainer" component={AITrainer} />
          <Route exact path="/premium/ai-career-counsellor" component={AICareerCounsellor} />
          <Route exact path="/premium/ultimate-career-kit" component={UltimateCareerKitPage} />
          <Route exact path="/premium/fitness-counsellor" component={FitnessCounsellor} />
          <Route exact path="/premium/life-coach" component={LifeCoach} />
          <Route exact path="/premium/face-to-face-career-guidance" component={FTFCareerGuidance} />
          <Route exact path="/premium/interview-coaching" component={InterviewCoaching} />

          <Route exact path="/investors" component={InvestorsLandingPage} />
          <Route path="/premium/job-seeker" component={JobSeekerPremiumPage} />

          <ProtectedRoute path="/premium/manage-products/:productName" component={ManageYourProducts} />
          <ProtectedRoute path="/premium/manage-products" component={ManageYourProducts} />
          <ProtectedRoute path="/unsubscribe/email/:recruiter_mail_id" component={UnsubscribePage} />
          <Route
            exact
            path="/premium"
            render={() =>
              loggedInUser.userMode === "HIRING" ? (
                <PricingPage loggedInUser />
              ) : loggedInUser.userMode === "SOCIAL" ? (
                <SocialPremiumPage />
              ) : (
                <MemberPremiumPage />
              )
            }
          />
          <Route exact path="/premium/hirer" component={HirerPremiumPage} />
          <ProtectedRoute exact path="/delete-account" component={DeleteAccountPage} />
          <Route
            path="/onboarding"
            render={() =>
              loggedInUser.user_id &&
                (onboardingStatus || loggedInUser.status === 2) ? (
                <OnboardingPage />
              ) : (
                <Redirect to={homepage} />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/feedback"
            component={() => <RequestSurveyPage type="FEEDBACK" />}
          />
          <ProtectedRoute
            exact
            path="/build-network"
            component={() => <RequestSurveyPage type="BUILD_NETWORK" />}
          />
          <ProtectedRoute
            exact
            path="/verification/skills"
            component={() => <RequestSurveyPage type="SKILLS" />}
          />
          <ProtectedRoute
            exact
            path="/verification/experience"
            component={() => <RequestSurveyPage type="EXPERIENCE" />}
          />
          <ProtectedRoute
            exact
            path="/verification/education"
            component={() => <RequestSurveyPage type="EDUCATION" />}
          />
          <ProtectedRoute
            exact
            path="/chat"
            component={() =>
              loggedInUser.userMode !== "SOCIAL" ? (
                <ChatServicePage />
              ) : (
                <Redirect to="/social/app" />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/blood-donors/chat"
            component={() => <ChatServicePage chatType="SOCIAL_SERVICES" />}
          />
          <ProtectedRoute
            exact
            path="/member/saved-jobs"
            component={SavedJobsPage}
          />
          <ProtectedRoute
            exact
            path="/member/shortlisted"
            component={() => <JobSeekerListsByType type="SHORTLISTED" />}
          />
          <ProtectedRoute
            exact
            path="/member/pending-opportunities"
            component={() => <JobSeekerListsByType type="PENDING" />}
          />
          <ProtectedRoute
            exact
            path="/member/applied-jobs"
            component={() => <JobSeekerListsByType type="APPLIED" />}
          />
          <ProtectedRoute
            exact
            path="/applications/:type"
            component={ApplicationsPage}
          />
          <ProtectedRoute
            exact
            path="/hirer/activity/jobs"
            component={HirerActivityPage}
          />
          <ProtectedRoute
            exact
            path="/hirer/activity/via-search"
            component={FoundViaSearch}
          />
          <ProtectedRoute
            path="/hirer/gcoins"
            component={() =>
              loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/dashboard" />
              ) : loggedInUser.userMode === "HIRING" ? (
                <HirerGcoinsWalletPage />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/notifications"
            component={NotificationsPage}
          />
          <ProtectedRoute
            exact
            path="/blood-donors/notifications"
            component={() => <NotificationsPage type="SOCIAL_SERVICES" />}
          />
          <ProtectedRoute
            exact
            path="/gcoins"
            component={() =>
              loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/dashboard" />
              ) : loggedInUser.userMode === "HIRING" ? (
                <Redirect to="/dashboard" />
              ) : (
                <GCoinsPage />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/purchase-gcoins"
            component={() =>
              loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/dashboard" />
              ) : loggedInUser.userMode === "HIRING" ? (
                <Redirect to="/dashboard" />
              ) : (
                <PurchaseGCoinsPage />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/profile"
            component={() =>
              loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/social/profile" />
              ) : loggedInUser.userMode === "HIRING" ? (
                <Redirect to="/hirer/profile" />
              ) : (
                <Redirect to={`/${userKeyword}`} />
              )
            }
          />
          <ProtectedRoute
            exact
            path="/chats"
            component={() => <Redirect to="/chat" />}
          />
          <ProtectedRoute
            exact
            path="/feedbackUser/:user_id_1"
            component={FeedbackUserPage}
          />
          <ProtectedRoute
            exact
            path="/chats"
            component={() => <Redirect to="/chat" />}
          />
          <ProtectedRoute
            exact
            path="/feedbackUser/:user_id_1"
            component={FeedbackUserPage}
          />
          <ProtectedRoute
            exact
            path="/reactivate-job"
            component={(props) => {
              console.priorityLog(props.location.search);
              if (props.location.search.includes("?offer_id="))
                sessionStorage.setItem(
                  "jobDataReactivate",
                  JSON.stringify({
                    offerId: parseInt(
                      props.location.search.substring("?offer_id=".length),
                      10
                    )
                  })
                );
              return <Redirect to="/create-job-post" />;
            }}
          />

          {/* <ProtectedRoute exact path="/my_job_offers" component={MyJobOffers} /> */}
          <ProtectedRoute
            exact
            path="/my_job_posts"
            component={MyPostedJobsPage}
          />
          <ProtectedRoute
            exact
            path="/my_job_posts/candidates/:offerId"
            component={JobCandidatesPage}
          />
          {/* <Route
            exact
            path="/post_requirement"
            component={PostRequirementPage}
          /> */}
          <Route exact path="/about_us" component={AboutUsPage} />
          <Route exact path="/ourteam" component={OurTeamPage} />
          <Route exact path="/careers" component={CareersPage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route path="/pricing" component={PricingPage} />
          <Route path="/blogs/:blog_id/:title" exact component={BlogDetails} />
          <Route path="/blogs" component={BlogsPage} />

          <Route path="/terms-of-use" component={TermsAndPolicy} />
          <Route path="/cookie-policy" component={TermsAndPolicy} />
          <Route path="/refund-policy" component={TermsAndPolicy} />
          <Route path="/privacy-policy" component={TermsAndPolicy} />

          <Route path="/faq" component={FaqPage} />

          <Route exact path="/m/faq" component={FaqPage} />

          {/* new Pages */}
          <Route
            exact
            path="/hirer/dashboard"
            render={() =>
              !localStorage.getItem("token") ? (
                <Redirect to="/" />
              ) : loggedInUser.userMode === "HIRING" ? (
                <HirerDashboard />
              ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                <Redirect to="/member/dashboard" />
              ) : (
                <Redirect to="/socialPage" />
              )
            }
          />
          <Route
            exact
            path="/social/dashboard"
            render={() =>
              !localStorage.getItem("token") ? (
                <Redirect to="/" />
              ) : loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/member/dashboard" />
              ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                <Redirect to="/member/dashboard" />
              ) : (
                <Redirect to="/hirer/dashboard" />
              )
            }
          />
          <Route
            exact
            path="/socialPage"
            component={SocialPage}
          />

          <Route
            exact
            path="/member/dashboard"
            render={() =>
              !localStorage.getItem("token") ? (
                <Redirect to="/" />
              ) : loggedInUser.userMode === "JOB_SEEKER" ? (
                <MemberDashboard />
              ) : loggedInUser.userMode === "SOCIAL" ? (
                <Redirect to="/socialPage" />
              ) : (
                <Redirect to="/hirer/dashboard" />
              )
            }
          />
          <Route
            exact
            path="/auto-recruiter-demo"
            component={AutoInterviewDemoPage}
          />
          <Route
            exact
            path="/auto-recruiter-demo/Cvent"
            component={AutoInterviewDemoPageCvent}
          />
          <Route exact path="/create-job-post"
            render={() =>
              <Redirect to="/create-job-post/step1" />
            }
          />
          <Route exact path="/update-job-post"
            render={() =>
              <Redirect to="/update-job-post/step1" />
            }
            />
          <Route path="/create-job-post/:step" component={CreateJobPage} />
          <Route path="/update-job-post/:step" component={UpdateJobPage} />
          <ProtectedRoute
            exact
            path="/select-hirers-to-pitch"
            component={SelectHirersForPitch}
          />
          <ProtectedRoute
            exact
            path="/select-users-to-send-intro"
            component={SelectUsersForIntro}
          />
          <Route exact path="/create-pitch" component={CreatePitchPage} />
          <ProtectedRoute exact path="/career-counselor/:sessionId" component={CareerCounselor} />
          {/* <ProtectedRoute exact path="/rohan" component={RohanBot} /> */}
          <ProtectedRoute exact path="/fitness/:sessionId" component={Fitness} />
          <ProtectedRoute exact path="/mock-interviewer/:sessionId" component={MockInterviewer} />
          <ProtectedRoute exact path="/skill-gap-analysis/:sessionId" component={SkillGapAnalyser} />
          <ProtectedRoute exact path="/trainer/:sessionId" component={AiTrainer} />
          <ProtectedRoute exact path="/life-coach/:sessionId" component={AiLifeCoach} />
          <ProtectedRoute
            exact
            path="/create-intro"
            component={CreateIntroPage}
          />
          <ProtectedRoute
            exact
            path="/preview-pitch"
            component={PitchPreviewPage}
          />
          <ProtectedRoute
            exact
            path="/preview-intro"
            component={PreviewIntroPage}
          />
          <ProtectedRoute
            exact
            path="/intro/:introId"
            component={PreviewIntroPage}
          />
          <ProtectedRoute exact path="/intros" component={MyIntroPage} />
          <ProtectedRoute
            exact
            path="/pitch/:pitchId"
            component={PitchPreviewPage}
          />
          {/* <ProtectedRoute
            exact
            path="/manage-jobs"
            component={ManageJobsPage}
          /> */}
          <Route
            exact
            path="/dashboard/:offerId"
            component={BoostParticularJobPage}
          />
          <Route
            exact
            path="/manage-jobs/:offerId"
            component={ManageParticularJobPage}
          />
          <ProtectedRoute
            exact
            path="/manage-jobs/:offerId/preview"
            component={JobPreviewPage}
          />
          <Route exact path="/payment/:status" component={PaymentStatus} />
          <Route
            exact
            path="/transaction/track/:transactionId"
            component={RedirectPage}
          />
          <ProtectedRoute
            exact
            path="/hirer/profile"
            component={EditHirerProfilePage}
          />

          {/* <Route
            exact
            path="/hirer/profile/edit"
            component={EditHirerProfilePage}
          /> */}
          <ProtectedRoute
            exact
            path="/social/profile"
            component={() => <SocialProfilePage pageOwner />}
          />
          <ProtectedRoute
            exact
            path="/social/experiences"
            component={SocialExperiencesPage}
          />
          <ProtectedRoute
            exact
            path="/social/search-people"
            component={SearchProfilesPage}
          />
          <ProtectedRoute
            exact
            path="/social/experiences/:socialExpDetails"
            component={ExperienceDetailsPage}
          />
          <ProtectedRoute
            exact
            path="/social/find-people"
            component={SocialFindPeoplePage}
          />
          <Route
            exact
            path="/social/user/:userId"
            render={() => <SocialProfilePage />}
          />
          <Route
            exact
            path="/job-seeker-faq"
            render={() => <FaqPageByMode userMode="JOB_SEEKER" />}
          />
          <Route
            exact
            path="/hirer-faq"
            render={() => <FaqPageByMode userMode="HIRING" />}
          />
          <Route exact path="/goodness-score" component={GoodnessScorePage} />
          <Route exact path="/navbar" component={Navbar} />
          <Route path="/social/onboarding" component={SocialOnboardingPage} />
          <Route
            exact
            path="/hirer/candidate-preferences"
            render={() => (
              <OnboardingLayout direct>
                <CandidatePreference direct />
              </OnboardingLayout>
            )}
          />
          <Route
            exact
            path="/hirer/company-details"
            render={() => (
              <OnboardingLayout direct>
                <CompanyDetails direct />
              </OnboardingLayout>
            )}
          />
          <ProtectedRoute
            exact
            path="/profile/add-skills"
            component={AddUserSkills}
          />
          <Route
            exact
            path="/activate-auto-recruiter/:offerId"
            component={ActivateAutoRecruiterOnJobPage}
          />
          <Route
            exact
            path="/activate-auto-recruiter/:offerId?back=/dashboard"
            component={ActivateAutoRecruiterOnJobPage}
          />
          <ProtectedRoute
            exact
            path="/auto-recruiter-activated/:offerId"
            component={PaymentSuccessful}
          />
          <Route exact path="/blood-donors" component={BloodDonors} />
          <Route
            exact
            path="/profile/:userDetails"
            render={(props) => {
              console.priorityLog(props);

              const extra = props.history.location.search || "";
              return (
                <Redirect to={`/${props.match.params.userDetails}${extra}`} />
              );
            }}
          />
          <Route
            exact
            path="/:userDetails"
            render={(props) => {
              console.log(props.history.location);
              if (props.history.location.pathname.includes("jobs"))
                return <JobsPage />;
              if (props.history.location.pathname.includes("hire"))
                return <SearchCandidatesPage />;
              return <ProfilePage />;
            }}
          />
          <Route 
            path="*"
            render={() => <Redirect to="/" />}
          />
        </Switch>
      </ScrollToTopAdapter>
    </Suspense>
  );
}
